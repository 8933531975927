import React from 'react';
import './Footer.scss';
import rightArrow from '../../assets/rightarrow.png';
import logo from '../../assets/logo2.png';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-left">
        <img src={logo} alt="Logo" />
        <p>© 2024 All Meal Prep Kitchen & Cafe - All Rights Reserved</p>
      </div>
      <div className="footer-center">
        <a href="#home" className="footer-menu-item">Home</a>
        <a href="#menu" className="footer-menu-item">Our Menu</a>
        <a href="#events" className="footer-menu-item">Events</a>
        <a
          href="https://allmealprep.com/kitchenandcafemenu" 
          target="_blank" 
          rel="noopener noreferrer"
          className="footer-menu-item"
        >
          Order Online
        </a>
        <a href="#contact" className="footer-menu-item">Contact Us</a>
      </div>
      <div className="footer-right">
        <a
          href="https://allmealprep.com/kitchenandcafemenu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="footer-button">
            Make a Reservation
            <img src={rightArrow} alt="Right Arrow" className="right-arrow" />
          </button>
        </a>
      </div>
    </footer>
  );
};

export default Footer;

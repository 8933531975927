// src/components/ContactSection/ContactSection.tsx

import React, { useState } from 'react';
import './ContactSection.scss';

import phoneIcon from '../../assets/phone.png';
import mailIcon from '../../assets/mail.png';
import pinIcon from '../../assets/pin.png';
import messageIcon from '../../assets/message.png';

import contact1 from '../../assets/contact1.png';
import contact2 from '../../assets/contact2.png';
import contact3 from '../../assets/contact3.png';
import Input from '../Input/Input';
import Button from '../Button/Button';

interface ContactSectionProps {
  id?: string; 
}

const ContactSection: React.FC<ContactSectionProps> = ({ id }) => {
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  return (
    <section className="contact-section" id={id}> 
      <div className="contact-info">
        <div className="contact-header">
          <span className="dot"></span>
          <h1 className="contact-title">Contact Us</h1>
          <span className="dot"></span>
        </div>
        <div className="contact-item-holder">
          <div className="contact-item">
            <img src={phoneIcon} alt="Phone Icon" className="icon" />
            <p>+1 (312) 313-2011</p>
          </div>
          <div className="contact-item">
            <img src={mailIcon} alt="Email Icon" className="icon" />
            <p>info@allmealprep.com</p>
          </div>
          <div className="contact-item">
            <img src={pinIcon} alt="Location Icon" className="icon" />
            <p>1100 W Bryn Mawr Ave, Chicago, IL 60660</p>
          </div>
        </div>
        <div className="contact-images">
          <img src={contact1} alt="Contact Image 1" className="contact-image" />
          <img src={contact2} alt="Contact Image 2" className="contact-image" />
          <img src={contact3} alt="Contact Image 3" className="contact-image" />
        </div>
      </div>

      <div className="contact-form">
        <div className='contact-form-holder'>
          <div className='contact-form-title '>
            <img src={messageIcon} alt="Message Icon" className="form-icon" />
            <h2 className="contact-form-title">
              Drop us a message
            </h2>
          </div>
          <Input label="First and Last Name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Your name" width="100%" />
          <Input label="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} placeholder="Subject" width="100%" />
          <Input label="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your email" width="100%" />
          <Input label="Message" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Your message" width="100%" height="100px" />
          <Button label="Send Message" variant="contained" className="send-button" />
        </div>
      </div>
    </section>
  );
};

export default ContactSection;

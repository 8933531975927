import React, { useState, useEffect } from 'react';
import './MenuSection.scss';
import menu1 from '../../assets/menu1.png';
import menu2 from '../../assets/menu2.png';
import menu3 from '../../assets/menu3.png';
import Button from '../Button/Button';

const menuImages = [menu1, menu2, menu3];

interface MenuSectionProps {
  id?: string; 
}

const MenuSection: React.FC<MenuSectionProps> = ({ id }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isSliderView, setIsSliderView] = useState(window.innerWidth <= 1150);

  useEffect(() => {
    const handleResize = () => {
      setIsSliderView(window.innerWidth <= 1150);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDotClick = (index: number) => {
    setActiveSlide(index);
  };

  return (
    <section className="menu-section" id={id}> 
      <div className="menu-header">
        <span className="dot"></span>
        <h2 className="menu-title">Our Menu</h2>
        <span className="dot"></span>
      </div>
      <p className="menu-description">
        Explore a variety of healthy meals, highlighting diverse flavors and global cuisines!
      </p>
      <div className="menu-images">
        {isSliderView ? (
          <img src={menuImages[activeSlide]} alt={`Menu ${activeSlide + 1}`} className="menu-image" />
        ) : (
          menuImages.map((src, index) => (
            <img key={index} src={src} alt={`Menu ${index + 1}`} className="menu-image" />
          ))
        )}
      </div>
      {isSliderView && (
        <div className="dots-container">
          {menuImages.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === activeSlide ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
      )}
      <div className="menu-button">
        <a 
          href="https://allmealprep.com/kitchenandcafemenu" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <Button label="Make A Reservation" variant="contained" color="primary" />
        </a>
      </div>
    </section>
  );
};

export default MenuSection;

// src/App.tsx

import React from 'react';
import Navbar from './components/Navbar/Navbar';
import HeroSection from './components/HeroSection/HeroSection';
import MenuSection from './components/MenuSection/MenuSection';
import WeeklyMealPrepsSection from './components/WeeklyMealPrepsSection/WeeklyMealPrepsSection';
import EventsSection from './components/EventsSection/EventsSection';
import ContactSection from './components/ContactSection/ContactSection';
import Footer from './components/Footer/Footer';

const App: React.FC = () => {
  return (
    <div>
      <Navbar />
      <HeroSection id="home" /> 
      <MenuSection id="menu" />   
      <WeeklyMealPrepsSection />
      <EventsSection id="events" /> 
      <ContactSection id="contact" />      
      <Footer/>
    </div>
  );
};

export default App;

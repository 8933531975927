// src/components/Navbar/Navbar.tsx

import React, { useState } from 'react';
import './Navbar.scss';
import Logo from './Logo/Logo';
import NavLinks from './NavLinks/NavLinks';
import Button from '../Button/Button';
import MobileMenu from './MobileMenu/MobileMenu';
import hamburgerIcon from '../../assets/hamburger.png';

const Navbar: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="navbar">
      <div className="container navbar__content">
        <Logo />

        <img
          src={hamburgerIcon}
          alt="menu"
          className="navbar__hamburger"
          onClick={toggleMobileMenu}
        />

        <div className="navbar__links">
          <NavLinks />
        </div>

        <div className="navbar__buttons">
          <a 
            href="https://allmealprep.com/kitchenandcafemenu" 
            target="_blank" 
            rel="noopener noreferrer"
            className="navbar__link-button"
          >
            <Button label="Make A Reservation" variant="contained" color="secondary" />
          </a>

          <a 
            href="https://allmealprep.com/guest/meal-prep-menu" 
            target="_blank" 
            rel="noopener noreferrer"
            className="navbar__link-button"
          >
            <Button label="Order Weekly Meal Preps" variant="contained" color="primary" />
          </a>
        </div>

        <MobileMenu isOpen={isMobileMenuOpen} onClose={toggleMobileMenu} />
      </div>
    </header>
  );
};

export default Navbar;
